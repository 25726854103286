import React from 'react'
import './styles/footer.css'
const Footer = () => {
  return (
    <div className='Footer-main-component-class '>
        <h1>Footer</h1>
    </div>
  )
}

export default Footer